<template>
  <main class="view view--week">
    <section class="header">
      <div class="header__left">
        <button v-on:click="prevWeek" class="btn btn-prev"></button>
      </div>

      <div class="header__center">
        <p class="title">
          {{ $t("Week") }} {{ this.from_date.isoWeek() }}
          {{ this.from_date.format("YYYY") }}
        </p>
        <p class="subtitle">
          {{ start_date().format("DD/MM") }} - {{ end_date().format("DD/MM") }}
        </p>
      </div>

      <div class="header__right">
        <button v-on:click="nextWeek" class="btn btn-next"></button>
      </div>
    </section>
    <div>
      <div class="timeregistration-summary timeregistration-summary-full">
        <div>
          {{ $t("Total") }}:
          <strong>{{
            $moment
              .duration(week_total, "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong>
        </div>
      </div>
      <div class="listitem listitem-sm">
        <div class="listitem__text">
          <strong>{{ $t("Date") }}</strong>
        </div>
        <div class="listitem__action">
          <strong>{{ $t("Total") }}</strong>
        </div>
      </div>
      <router-link
        :to="{ name: 'overview_day', query: { date: day.id } }"
        class="listitem listitem-sm"
        v-for="day in days"
        :key="day.id"
        :class="
          day.moment.day() === 6 || day.moment.day() === 0
            ? 'icon--disabled'
            : ''
        "
      >
        <div class="listitem__day">
          <div class="day">{{ day.moment.format("DD") }}</div>
          <div class="month">{{ day.moment.format("MMM") }}</div>
        </div>
        <div class="listitem__text">
          {{ day.label }}
        </div>
        <div class="listitem__action">
          {{
            $moment
              .duration(day.total, "milliseconds")
              .format("HHumm", { trim: false })
          }}
        </div>
      </router-link>
    </div>
  </main>
</template>

<script>
export default {
  name: "overview",
  mounted: function () {
    this.updateView();
  },
  methods: {
    prevWeek: function () {
      this.from_date = this.from_date.subtract(1, "week");
      this.updateView();
    },
    nextWeek: function () {
      this.from_date = this.from_date.add(1, "week");
      this.updateView();
    },
    getDates: function (start, end) {
      let dte_days = this.$helper.datesBetween(start, end);
      let days = [];
      for (let i = 0; i < dte_days.length; i++) {
        let moment_day = this.$moment(dte_days[i]);
        let day = {
          id: moment_day.format("YYYY-MM-DD"),
          moment: moment_day,
          label: this.$helper.ucfirst(moment_day.format("dddd")),
        };
        days.push(day);
      }

      return days;
    },
    updateView: function () {
      this.$api
        .getTrackings(
          this.account.id,
          this.start_date().format("YYYY-MM-DD"),
          this.end_date().format("YYYY-MM-DD")
        )
        .then((timetrackings) => {
          var days = this.getDates(
            this.start_date().toDate(),
            this.end_date().toDate()
          );
          this.days = days;
          this.week_total = 0;
          for (let j = 0; j < this.days.length; j++) {
            var day = this.days[j];
            day.total = 0;

            var day_trackings = timetrackings[day.id];
            if (day_trackings) {
              for (let i = 0; i < day_trackings.length; i++) {
                const tracking = day_trackings[i].tracking;
                if (tracking) {
                  let end = this.$moment(tracking.end, "HH:mm:ss");
                  let start = this.$moment(tracking.start, "HH:mm:ss");
                  var duration = this.$moment
                    .duration(end.diff(start))
                    .subtract(tracking.pause);
                  day.total += duration.asMilliseconds();
                }
              }
            }

            this.week_total += day.total;
            this.days[j] = day;
          }

          this.$forceUpdate();
        })
        .catch(() => {
          this.days = this.getDates(
            this.start_date().toDate(),
            this.end_date().toDate()
          );
          this.$forceUpdate();
        });
    },
    start_date: function () {
      return this.$moment(this.from_date).startOf("isoWeek");
    },
    end_date: function () {
      return this.$moment(this.from_date).endOf("isoWeek");
    },
  },
  data: function () {
    return {
      account: this.$config.get("account"),
      from_date: this.$route.query.date
        ? this.$moment(this.$route.query.date)
        : this.$moment(),
      days: [],
      week_total: 0,
    };
  },
};
</script>
